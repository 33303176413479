<!--故障列表 -->
<template>
  <div class="warp">
    <el-card class="warp-content">
      <!-- 搜素区域 -->
      <div class="page-select">
        <el-row style="width: 100%; margin: 0 0 15px 0">
          <el-col :span="24" style="text-align: right">
            <el-input
              placeholder="请输入卡号"
              class="search-input"
              prefix-icon="search"
              size="small"
              @input="searchInput"
              v-model="cardMark"
            >
            </el-input>
            <el-select
              clearable
              class="search-input"
              placeholder="请选择卡状态"
              size="small"
              v-model="status"
              @change="changeValue"
            >
              <el-option
                v-for="item in statuslist"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                {{ item.label }}
              </el-option>
            </el-select>
            <el-date-picker
              v-model="actionTime"
              size="small"
              type="date"
              placeholder="请选择创建时间"
              clearable
              value-format="YYYY-MM-DD"
              @change="selectPages"
              style="width: 302px; margin-right: 4px"
            />
            <el-button type="primary" size="small" @click="selectPages"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </div>

      <!-- 按钮操作区域 -->
      <div class="page-select">
        <el-row style="width: 100%; margin: 0 0 15px 0">
          <el-col :span="24">
            <el-button
              type="primary"
              size="small"
              @click="handelClickEditAdd"
              v-if="userLevel === 0"
            >
              <el-icon :size="15"> <plus /> </el-icon><span>新增</span>
            </el-button>
            <el-button
              type="danger"
              size="small"
              @click="batchDelete"
              plain
              v-if="userLevel === 0"
            >
              <el-icon :size="15"> <delete-filled /> </el-icon
              ><span>批量删除</span>
            </el-button>
            <!-- <el-button
              type="primary"
              size="small"
              @click="downloadCard"
              v-if="userLevel === 0"
            >
              导出Excel
            </el-button> -->
            <el-button
              type="primary"
              size="small"
              @click="downloadExcel"
              v-if="userLevel === 0"
            >
              导出模板
            </el-button>
            <label for="my-uploader" v-if="userLevel === 0">
              <div class="import-btn-class">导入充电卡</div>
            </label>
            <input
                id="my-uploader"
                style="display: none"
                ref="uploaderInputRef"
                accept=".xls"
                type="file"
                @change="importDevice" />
          </el-col>
        </el-row>
      </div>

      <!-- 表格区域 -->
      <div class="page-container">
        <el-table
          ref="tableRef"
          :data="tableData"
          style="width: 100%"
          max-height="630px"
          :header-cell-style="{ background: '#F2F4F8' }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" align="center" width="80" />
          <el-table-column
            v-for="(item, index) in tableColumnData"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            align="center"
          >
            <template #default="{ row }" v-if="item.prop === 'status'">
              <span v-show="row.status == 0" style="color: #cd2b1d">{{
                "未绑定"
              }}</span>
              <span v-show="row.status == 1" style="color: #3ea422">{{
                "正常"
              }}</span>
              <span v-show="row.status == 2" style="color: #cd2b1d">{{
                "挂失"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" v-if="userLevel == 0">
            <template #default="{ row }">
              <el-button type="text" @click="editClick(row)">编辑</el-button>
              <el-divider direction="vertical"></el-divider>
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="confirmEvent(row)"
                :title="'确认是否删除' + '“' + row.cardMark + '”?'"
              >
                <template #reference>
                  <el-button type="text">删除</el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页区域 -->
      <div class="page-foot">
        <page-nation
          :total="total"
          @pageChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        ></page-nation>
      </div>
    </el-card>

    <!-- 组织 导入进度条 -->
    <el-dialog
      v-model="visiblePro"
      width="30%"
      :close-on-click-modal="false"
      @closed="resetForms"
    >
      <el-progress :percentage="100" :format="format" :indeterminate="true" />
      <div class="houtai">
        <span @click="resetForms">注：可关闭后台运行...</span>
      </div>
    </el-dialog>

    <!-- 新建、编辑充电卡 -->
    <el-dialog
      v-model="visible"
      width="40%"
      :title="titleName"
      :close-on-click-modal="false"
      @closed="resetForm"
    >
      <el-form
        label-width="95px"
        :inline="true"
        :model="formData"
        :rules="addRules"
        ref="ruleForm"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="卡号：" prop="cardMark">
              <el-input
                placeholder="请输入卡号"
                v-model="formData.cardMark"
                :disabled="viewDetailDisabled"
                type="number"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="余额：" prop="cardBalance">
              <el-input
                placeholder="请输入余额"
                v-model="formData.cardBalance"
                type="number"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="赠送金额：" prop="presenter">
              <el-input
                placeholder="请输入赠送金额"
                v-model="formData.presenter"
                type="number"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="titleName == '编辑充电卡'">
            <el-form-item label="卡状态：" prop="status">
              <el-radio-group v-model="formData.status">
                <el-radio
                  v-for="item in statusTypeOptions"
                  :key="item.value"
                  :label="item.value"
                  >{{ item.label }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm">取消 </el-button>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >保存</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
  
  <script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue-demi";
import {
  selectPagesApi,
  addCardApi,
  upCardApi,
  delCardApi,
  exportTemplateAPi,
  importXlsAPi,
  getStatusApi,
  cardFaultApi
} from "@/api/chargeCardManagement/chargeCardManagement.js";
import { requestApi } from "@/utils/requestApi";
import { debounces } from "@/common/utils";
import pageNation from "@/components/base/pageNation";
export default {
  name: "AccountSearch",
  components: { pageNation },
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      actionTime: "", //搜索创建时间
      tableData: [], // 表格数据
      errors: {}, //验证提示信息
      total: 0,
      pageSize: 10,
      currentPage: 1,
      titleName: "",
      visible: false,
      visiblePro: false, // 组织导入进度条
      timer: null,
      viewDetailDisabled: false,
      statusTypeOptions: [
        { value: 0, label: "未绑定" },
        { value: 1, label: "正常" },
        { value: 2, label: "挂失" },
      ],
      userInfo: null,
      userLevel: 0, //用户等级
      selection: [],
      disabled: true,
      formData: {
        cardMark: "",
        cardBalance: "",
        presenter: "",
        status: 0,
      },
      addRules: {
        cardMark: [{ required: true, message: "请输入卡号", trigger: "blur" }],
        cardBalance: [
          { required: true, message: "请输入余额", trigger: "blur" },
        ],
        presenter: [
          {
            required: true,
            message: "请输入赠送金额",
            trigger: ["blur", "change"],
          },
        ],
      },
      tableColumnData: [
        {
          prop: "cardMark",
          label: "卡号",
        },
        {
          prop: "cardUser",
          label: "用户id",
        },
        {
          prop: "cardBalance",
          label: "余额",
        },
        // {
        //   prop: "practical",
        //   label: "实际支付金额",
        // },
        {
          prop: "refillTotal",
          label: "充值总金额"
        },
        {
          prop: "consumeTotal",
          label: "消费总金额"
        },
        {
          prop: "presenter",
          label: "赠送金额",
        },
        {
          prop: "status",
          label: "卡状态",
        },
        {
          prop: "createUser",
          label: "创建人",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
      ],
      cardMark: "",
      status: "",
      statuslist: [
        // 0未绑定1绑定2挂失
        {
          label: "未绑定",
          value: 0,
        },
        {
          label: "正常",
          value: 1,
        },
        {
          label: "挂失",
          value: 2,
        },
      ],
    });
    onMounted(() => {
      proxy.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      proxy.$nextTick(() => {
        proxy.userLevel = proxy.userInfo.chargingMerchant.level;
      });
      methods.selectPages();
    });
    // 节流搜索按钮
    const searchInput = debounces((value) => {
      // if(methods.validatePhone()) methods.selectPages()
      methods.selectPages();
    }, 500);
    const methods = {
      handleSizeChange(val) {
        proxy.pageSize = val;
        methods.selectPages();
      },
      handleCurrentChange(val) {
        proxy.currentPage = val;
        methods.selectPages();
      },
      changeValue(value) {
        // console.log('我触发了', val);
        proxy.status = value;
        methods.selectPages();
      },
      async selectPages() {
        await requestApi(
          () => {
            const params = {
              current: proxy.currentPage,
              pageSize: proxy.pageSize,
              actionTime: proxy.actionTime,
              cardMark: proxy.cardMark,
              cardUser: "",
              status: proxy.status,
            };
            return selectPagesApi(params);
          },
          (res) => {
            if (res.code === 200) {
              proxy.total = res.data.total;
              proxy.currentPage = res.data.current;
              proxy.pageSize = res.data.size;
              proxy.tableData = res.data.records.map((it) => {
                return {
                  cardMark: it.cardMark, // 卡号
                  cardUser: it.cardUser, // 用户id
                  cardBalance: it.cardBalance, // 余额
                  practical: it.practical, // 实际支付金额
                  presenter: it.presenter, // 赠送金额
                  status: it.status, // 卡状态
                  createUser: it.createUser, // 创建人
                  createTime: it.createTime, // 创建时间
                  cardId: it.cardId, // 主键
                  refillTotal: (Number(it.refillTotal) / 100).toFixed(2),   // 充值总金额
                  consumeTotal: (Number(it.consumeTotal) / 100).toFixed(2)   // 消费总金
                }
              });
              console.log("成功", proxy.tableData);
            }
          }
        );
      },
      async addCard() {
        await requestApi(
          () => {
            proxy.formData.createUser =
              proxy.userInfo.chargingMerchant.merchantName;
            return addCardApi(proxy.formData);
          },
          (res) => {
            if (res.code == 200) {
              proxy.$notify({
                title: "成功",
                message: res.message,
                type: "success",
                duration: 1000,
              });
            }
          }
        );
      },
      async upCard() {
        await requestApi(
          () => {
            proxy.formData.createUser =
              proxy.userInfo.chargingMerchant.merchantName;
            return upCardApi(proxy.formData);
          },
          (res) => {
            if (res.code == 200) {
              proxy.$notify({
                title: "成功",
                message: res.message,
                type: "success",
                duration: 1000,
              });
            }
          }
        );
      },
      async delCard(data) {
        await requestApi(
          () => {
            return delCardApi(data);
          },
          (res) => {
            if (res.code == 200) {
              proxy.$notify({
                title: "成功",
                message: res.message,
                type: "success",
                duration: 1000,
              });
              proxy.selectPages();
            }
          }
        );
      },
      confirmEvent(data) {
        methods.delCard([data.cardId]);
      },
      // 勾选
      handleSelectionChange(val) {
        proxy.selection = val.map((it) => it.cardId);
      },
      // 批量删除
      batchDelete() {
        if (proxy.selection.length > 0) {
          proxy.$msgbox
            .confirm("确认是否删除所选数据?", "删除", {
              closeOnClickModal: false,
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
            .then(async () => {
              //   console.log(proxy.selection)
              methods.delCard(proxy.selection);
            })
            .catch(() => {
              proxy.$refs.tableRef.clearSelection();
            });
        }
      },
      handelClickEditAdd() {
        proxy.titleName = "新增充电卡";
        proxy.visible = true;
        proxy.viewDetailDisabled = false;
      },
      editClick(data) {
        proxy.titleName = "编辑充电卡";
        proxy.visible = true;
        proxy.viewDetailDisabled = true;
        proxy.$nextTick(() => {
          proxy.formData = {
            cardId: data.cardId,
            cardMark: data.cardMark,
            cardBalance: data.cardBalance,
            presenter: data.presenter,
            status: data.status,
          };
        });
      },
      // 保存
      submitForm(formName) {
        proxy.$refs[formName].validate(async (valid) => {
          if (valid) {
            if (proxy.titleName == "新增充电卡") {
              await methods.addCard();
            } else {
              await methods.upCard();
            }
            proxy.formData = {
              cardMark: "",
              cardBalance: "",
              presenter: "",
              status: "",
            };
            proxy.visible = false;
            await methods.selectPages();
          }
        });
      },
      // 取消
      resetForm() {
        proxy.formData = {
          cardMark: "",
          cardBalance: "",
          presenter: "",
          status: "",
        };
        proxy.visible = false;
      },
      resetForms() {
        proxy.visiblePro = false;
      },
      // 导出设备
      downloadCard () {
        if (proxy.selection.length > 0) {
          proxy.$msgbox
            .confirm('确认是否将选中的数据导出?', '导出', {
              closeOnClickModal: false,
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
            .then(() => {
              methods.deriveXlsAll()
            }).catch(() => {
              proxy.$refs.tableRef.clearSelection()
          })
        } else {
          proxy.$msgbox
              .confirm('确认是否将全部数据导出?', '导出', {
                closeOnClickModal: false,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
              .then(() => {
                methods.deriveXlsAll()
              }).catch(() => {
            proxy.$refs.tableRef.clearSelection()
          })
        }
      },
      // 导出设备接口
      deriveXlsAll () {
        const ids = proxy.selection + ''
        const params = {
          ids: ids,
          merchantId: data.userInfo.merchantId
        }
        requestApi(
          () => {
            return deriveXlsAllAPi(params)
          },
          (res) => {
            methods.downloadFile(res,'设备管理')
          }
        )
      },
      // 下载模板
      downloadExcel() {
        requestApi(
          () => {
            return exportTemplateAPi()
          },
          (res) => {
            methods.downloadFile(res,'充电卡导入模板')
          }
        )
      },
      // 处理下载请求
      downloadFile(res,name) {
        const url = URL.createObjectURL(new Blob([res.data]));
        const a = document.createElement("a");
        document.body.appendChild(a); // 此处增加了将创建的添加到body当中
        a.href = url;
        a.download = name + new Date().getTime() + ".xls";
        a.target = "_blank";
        a.click();
        a.remove(); // 将a标签移除
        proxy.$refs.tableRef.clearSelection();
      },
      // 导入充电卡
      importDevice(event) {
        // 限制文件上传的个数
        let length = event.target.files.length;
        if (length > 2) {
          proxy.$notify({
            title: "提示",
            message: "每次只能上传一个文件",
            type: "success",
            duration: 1000,
          });
          return;
        }
        /* 限制文件上传大小 */
        let file = event.target.files[0];
        let isLtSize = file.size / 1024 / 1024 < 10;
        if (!isLtSize) {
          proxy.$notify({
            title: "提示",
            message: "上传文件不能超过 10MB!",
            type: "warning",
            duration: 1000,
          });
          return;
        }

        let formData = new FormData();
        formData.append("file", file);
        methods.importXls(formData);
        this.$refs.uploaderInputRef.value = null;
      },
      // 导入接口
      importXls(formData) {
        requestApi(
          () => {
            return importXlsAPi(formData);
          },
          (res) => {
            // proxy.$notify({
            //   title: "成功",
            //   message: res.message,
            //   type: "success",
            //   duration: 1000,
            // });
            // methods.selectPages();
            proxy.visiblePro = true;
              // 定时2秒查询接口
              proxy.timer = window.setInterval(() => {
                setTimeout(() => {
                  // 调取接口 设定2秒（setTimeout是自带清除定时器）
                  methods.getStatus();
                }, 100);
              }, 10000);
          }
        );
      },
      // 查询导入组织是否成功
      async getStatus() {
        try {
          await getStatusApi().then((res) => {
            if (res.code == 200) {
              if (res.data == 1) {
                proxy.visiblePro = false;
                clearInterval(proxy.timer); //清除定时器
                proxy.$notify({
                  title: "成功",
                  message: "导入完成！",
                  type: "success",
                  duration: 2000,
                });
                methods.selectPages();
              }
            }
          });
        } catch (e) {
          console.log(e);
          if (e.code == 201 && e.data == '2') {
            proxy.visiblePro = false;
            methods.selectPages();
            methods.cardFault(); // 导出错误列表
            clearInterval(proxy.timer); //清除定时器
            proxy.$notify({
              title: "导入失败！",
              message: e.msg,
              type: "warning",
              duration: 3000,
            });
          } else if (e.data == 3) {
            proxy.visiblePro = false;
            methods.selectPages();
            clearInterval(proxy.timer); //清除定时器
            proxy.$notify({
              title: "导入失败！",
              message: e.msg,
              type: "error",
              duration: 2000,
            });
          }
        }
      },
      cardFault() {
        requestApi(
          () => {
            return cardFaultApi()
          },
          (res) => {
            if (res.status === 200) {
            const url = URL.createObjectURL(new Blob([res.data]));
            const a = document.createElement("a");
            document.body.appendChild(a); // 此处增加了将创建的添加到body当中
            a.href = url;
            a.download = "导入充电卡错误文档" + new Date().getTime() + ".xls";
            a.target = "_blank";
            a.click();
            a.remove(); // 将a标签移除
            proxy.$refs.multipleTable.clearSelection();
          }
          }
        )
      }
    };
    return {
      ...toRefs(data),
      searchInput,
      ...methods,
    };
  },
};
</script>
  <style lang="less" scoped>
.warp {
  height: 100%;
  .warp-content {
    height: 100%;
  }
}
.page-select {
  width: 100%;
  margin: 0 auto;
}
.page-container {
  width: 100%;
  //margin-top: -28px;
  .imgeTou {
    width: 35px;
    height: 35px;
  }
}
.page-foot {
  width: 100%;
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .my-btn {
    height: 28px;
  }
}
.search-input {
  width: 202px;
  margin-right: 4px;
  margin-top: 6px;
}

.search-input-class {
  width: 100%;
}
.import-btn-class {
  display: inline;
  margin: 0 10px;
  min-height: 32px;
  padding: 8px 15px;
  font-size: 12px;
  color: #fff;
  background: #409eff;
  border-radius: 3px;
  outline: none;
  border: none;
  cursor: pointer;
}
.TipsClass {
  font-size: 12px;
  line-height: 18px;
  color: rgb(218, 10, 10);
}
/deep/.el-form-item__label {
  white-space: nowrap;
}
/deep/.el-form-item {
  width: 100%;
}
</style>
  