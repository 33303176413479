import { axios } from '@/utils/request'

const api = {
    selectPages: 'device/card/selectPages', // 分页查询充电卡
    addCard: 'device/card/addCard',    // 新增充电卡
    upCard: 'device/card/upCard',       // 编辑充电卡
    delCard: 'device/card/delCard',     // 删除充电卡
    exportTemplate: 'device/card/exportTemplate',   // 导出模板
    importXls: 'device/card/importXls',  // 导入充电卡
    getStatus: 'device/card/getStatus',   // 获取导入状态
    cardFault: 'device/cardFault/exportTemplate'    // 导出错误列表
}
export default api

// 查询
export const selectPagesApi = (params) => {
    return axios({
        url: api.selectPages,
        method: 'post',
        params
    })
}

// 新增
export const addCardApi = (data) => {
    return axios({
        url: api.addCard,
        method: 'post',
        data
    })
}

// 编辑
export const upCardApi = (data) => {
    return axios({
        url: api.upCard,
        method: 'post',
        data
    })
}

// 删除
export const delCardApi = (data) => {
    return axios({
        url: api.delCard,
        method: 'post',
        data
    })
}

// 导出模板
export const exportTemplateAPi = (data) => {
    return axios({
        url: api.exportTemplate,
        responseType: 'blob',
        method: 'get',
        data
    })
}

// 导入充电卡
export const importXlsAPi = (data) => {
    return axios({
        url: api.importXls,
        responseType: 'blob',
        method: 'post',
        data
    })
}

// 查询用户导入状态
export function getStatusApi(data) {
    return axios({
      url: api.getStatus,
      method: 'post',
      data
    })
}

// 导出错误列表
export function cardFaultApi() {
    return axios({
      url: api.cardFault,
      method: 'get',
      responseType: 'blob'
    })
}